@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Noto+Serif+SC:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Ovo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app{
  height: 100%;
  background-color: #fdfbec;
  @apply text-black;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

:root {
  --tape-gray: #dbd8be;
  --tape-edge-gray: #b7b49d;
  --transparent: rgba(255, 255, 255, 0);
}


.paper {
  position: relative;
  background: 
    #fdfbf1;
 
  box-shadow: 3px 3px 2px #eddfb9;
}


.tape-section {
  position: absolute;
  width: 100%;
}

.top-tape {
  position: absolute;
  height: 4vmin;
  top: -12px;
  width: 60%;
  background-color: var(--tape-gray);
  border-right: 1px dotted var(--tape-edge-gray);
  border-left: 1px dotted var(--tape-edge-gray);
  opacity: 0.5;
}

.tape-section:first-of-type {
  top: 0;
}

.tape-section:last-of-type {
  bottom: 0;
}

.tape-section::before,
.tape-section::after {
  content: "";
  width: 10vmin;
  height: 4vmin;
  position: absolute;
  background-color: var(--tape-gray);
  opacity: 0.5;
  border-right: 1px dotted var(--tape-edge-gray);
  border-left: 1px dotted var(--tape-edge-gray);
}

.tape-section:last-of-type::after {
  transform: rotate(-45deg);
  right: -4vmin;
  top: -3vmin;
}

.tape-section:first-of-type::before {
  transform: rotate(-45deg);
  left: -4vmin;
}

.tape-section:first-of-type::after {
  transform: rotate(45deg);
  right: -4vmin;
  top: 0;
}

.tape-section:last-of-type::before {
  transform: rotate(45deg);
  left: -4vmin;
  bottom: 0;
}




